import React from 'react'
import '../../App'
import AccommodationCard from './AccommodationCard';
import accomoData from '../../accommodationData.json'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, EffectCoverflow, Navigation } from "swiper";

const AccommodationSlider = () => {

return (
    <div className='w-full mx-auto pt-10 lg:pt-32 pb-10'>
    <Swiper
    slidesPerView={1.5}
    spaceBetween={0}
    effect={"coverflow"}
    grabCursor={true}
    centeredSlides={true}
    loop={true}
    pagination={false}
    coverflowEffect={{
      rotate: 10,
      depth: 100,
      modifier: 1,
      slideShadows: false,
    }}
    modules={[EffectCoverflow, Pagination, Navigation]}
    className="mySwiper"
    navigation
    breakpoints= {{
      0: {
        slidesPerView: 1,
      },
      769: {
        slidesPerView: 1.5,
      }
      }}
  >
      {accomoData.map(data => 
        <SwiperSlide key={data.id}>{({ isActive }) => (<AccommodationCard data={data} isActive={isActive} />)}</SwiperSlide>
        )}
  </Swiper>
  </div>
  )
}

export default AccommodationSlider