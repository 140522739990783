import React from 'react'
import SubTitle from './SubTitle'

const Rooms = () => {
  return (
    <div className="mt-5 mb-10 lg:my-10 text-th-white">
    <SubTitle title={'rooms'} />
    <div className='w-11/12 lg:w-9/12 mx-auto my-8 lg:my-10'>
    <h4 className='text-lg lg:text-xl font-secondary text-justify leading-4 md:leading-tight md:text-center'>
    Hotel at the Waldorf is not your standard hotel. each room focuses on the experience instead of the size of the room or the thread count of the sheets.
    </h4>
    <p className='font-primary leading-relaxed lg:leading-none mt-4 md:pt-3 text-sm lg:text-base'>
    Hotel at the Waldorf is an affordable alternative to staying in the hustle and bustle of the city. The hotel has a quirky, artistic, and casual feeling throughout, as no two rooms are alike. It's perfect for spontaneous last minute overnight stays, a crash pad after a night out in the city, or for the budget conscious traveler.
    </p>
    </div>
    </div>
  )
}

export default Rooms