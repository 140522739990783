import React from 'react'
import { Link } from 'react-router-dom'

const RoomCard = ({props}) => {
  return (
    <div className={`w-full lg:w-11/12 mx-auto my-7 lg:mb-48 lg:mt-32 h-fit relative text-th-gray flex justify-center items-center flex-col lg:flex-row ${props.id % 2 === 0 ? 'lg:justify-end' : 'lg:justify-start'}`}>
        <img src={props.head_pic} alt="roomimage" className='w-11/12 lg:w-3/5 h-[200px] md:h-[350px] lg:h-[600px] object-cover' />
        <div className={`static lg:absolute flex flex-col justify-around bg-th-white p-4 md:p-6 h-fit md:h-5/6 lg:h-full xl:h-5/6 w-11/12 md:w-10/12 lg:w-2/4 lg:top-1/2 lg:-translate-y-1/2 ${props.id % 2 === 0 ? 'right-1/2' : 'left-1/2'}`}>
            <div className='flex flex-col'>
             <div className='flex items-end justify-end'>
                 {/* <span className='text-5xl md:text-7xl xl:text-8xl 2xl:text-9xl font-secondary text-th-gray opacity-20 tracking-wider'>0{props.id}</span> */}
            <h2 className='font-secondary text-2xl lg:text-4xl xl:text-5xl tracking-wide uppercase text-right md:text-left'>
            {props.name}
            </h2>
             </div>
            <span className='w-full h-0.5 bg-th-primary'></span>
            <p className='text-right text-lg md:text-xl lg:text-2xl font-secondary tracking-wide md:tracking-widest pt-1 font-semibold md:font-bold uppercase'>{props.sub_name}</p>
            </div>

            <p className='my-4 lg:mb-0 lg:mt-5 2xl:mt-8 font-primary text-base md:text-lg h-full lg:h-56 xl:h-32 2xl:h-28'>
              <span className='hidden md:block'>
                {props.detail.length > 400 ? props.detail.slice(0, 400).concat('...') : props.detail }
              </span>
              <span className='block md:hidden'>
                {props.detail.length > 200 ? props.detail.slice(0, 200).concat('...') : props.detail }
              </span>
            </p>
            <Link to={`/room/${props.id}`} className='text-sm md:text-base lg:text-lg flex justify-end items-center font-semibold gap-2 mt-2 lg:mt-4 xl:mt-7 2xl:mt-14 mr-0 md:mr-7 font-primary tracking-wider'>
                Check room details
            <svg
                width="40"
                height="19"
                viewBox="0 0 62 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M61.7034 9.5L53.0432 0.839746L44.3829 9.5L53.0432 18.1603L61.7034 9.5ZM0.919922 11H53.0432V8H0.919922V11Z"
                  fill="#91CCBD" 
                />
              </svg></Link>
        </div>
    </div>
  )
}

export default RoomCard