import styled from "styled-components";

export const HeroTitle = styled.h1`
text-align: center;
width: 100%;
white-space: nowrap;
font-family: 'Libre Bodoni';
font-weight: 400;
font-size: 65px;
line-height: 160px;
letter-spacing: 0.4em;
color: #FFFFFF;
text-shadow: 4px 4px 4px rgba(105, 103, 103, 0.64);
@media (max-width: 1560px) {
  font-size: 3em;
  line-height: 120px;
  white-space: unset;
  }
  @media (max-width: 1200px) {
    width: 95%;
    margin: 0 auto;
    font-size: 3.5em;
    line-height: 75px;
  }
@media (max-width: 968px) {
  width: 95%;
  margin: 0 auto;
  padding: 0px 5px;
  font-size: 3em;
  letter-spacing: 0.2em;
  line-height: 75px;
  }

@media (max-width: 600px) {
  width: 95%;
  margin: 0 auto;
  font-size: 2.5em;
  letter-spacing: 0.1em;
  }

@media (max-width: 375px) {
  width: 90%;
  margin: 0 auto;
  font-size: 2em;
  letter-spacing: 0.1em;
  }
`
export const AboutImgBg = styled.div`
  width: 48%;
  max-width: 1000px;
  height: 45vh;
  margin: auto;
  background: url("assets/images/about_us.jpg");
  background-size: cover;
  background-position: center;
  position: relative; 
  left: 0;
  top: 50px;
  color: #fff;
  text-align: center;
  background-repeat: no-repeat, repeat;
  box-shadow: -60px -110px 0 rgba(145, 204, 189, 0.7)
;
  z-index: 0;
  @media (max-width: 1440px) {
    width: 45%;
    height: 45vh;
    max-height: 300px;
  }

  @media (max-width: 1024px) {
    width: 80%;
    height: 40vh;
    max-height: 300px;
    box-shadow: -60px -80px 0 rgba(240, 157, 139, 0.6);
    top: 10px;
  }

  @media (max-width: 768px) {
    width: 98%;
    height: 30vh;
    top: -35px;
    max-height: 150px;
    box-shadow: -100px -70px 0 rgba(240, 157, 139, 0.6);
  }
`;

