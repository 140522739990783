import styled from 'styled-components'

export const EventsBg = styled.div`
    background: linear-gradient(90deg, #6D6E71 0%, #6D6E71 40%, #F09D8B 40%, #F09D8B 60%, #6D6E71 60%, #6D6E71 100%);
    
  @media (max-width: 768px) {
    background: linear-gradient(90deg, #6D6E71 0%, #6D6E71 30%, #F09D8B 30%, #F09D8B 70%, #6D6E71 70%, #6D6E71 100%);

  }
`
