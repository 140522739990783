import React from 'react'
import { Helmet } from 'react-helmet'

const Header = ({subtitle}) => {
  return (
    <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#6D6E71" />
    <meta
      name="description"
      content="Downtown Vancouver is easily accessed and just minutes away from Hotel at the Waldorf. The hotel is also situated a short way from Commercial Drive and Gastown; two eclectic and historic hubs with the newest restaurants and boutique shops."
    />
    <link rel="apple-touch-icon" href="/logo192.png" />
    <link rel="manifest" href="/manifest.json" />
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous" />
    
    <title>Hotel At The Waldorf &nbsp;| &nbsp;  {subtitle && subtitle}</title>

    </Helmet>
  )
}

export default Header