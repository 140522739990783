import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  
} from "react-router-dom";
import App from './App';
import AccommodationPage from './pages/AccommodationPage';
import AboutUs from './pages/AboutUs';
import Room from './pages/Room';
import ContactPage from './pages/ContactPage';
import History from './pages/History';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import EventsPage from './pages/EventsPage';
import Explore from './pages/Explore';
import ScrollToTop from './ScrollToTop';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
  <ScrollToTop />
  <Routes>
    <Route path="/" element={<App />} />
    <Route path="about" element={<AboutUs />} />
    <Route path="accommodations" element={<AccommodationPage />} />
    <Route path='room' element={<Room />}>
      <Route path=':roomId' element={<Room />} />
    </Route>
    <Route path="contact" element={<ContactPage />} />
    {/* <Route path="history" element={<History />} /> */}
    <Route path="gallery" element={<Gallery />} />
    {/* <Route path="services" element={<Services />} /> */}
    {/* <Route path="events" element={<EventsPage />} /> */}
    <Route path="explore" element={<Explore />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
</BrowserRouter>
);

reportWebVitals();
