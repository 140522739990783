import React from 'react'

const Map = () => {
  return (
      <div className='mt-32 lg:mt-28 mb-28 w-screen h-[300px] lg:h-[500px]'>
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2602.7297449113335!2d-123.07650068431064!3d49.281517979331134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867113fbcd90f7%3A0x98a140004b6e5cdb!2sHotel%20At%20The%20Waldorf!5e0!3m2!1sen!2sca!4v1663707187096!5m2!1sen!2sca"
        className='w-full h-full grayscale'
        loading="lazy" referrerPolicy="no-referrer-when-downgrade" referrerpolicy="no-referrer-when-downgrade" title="waldorfhotelmap"></iframe>
      </div>
  )
}

export default Map