import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Aboutmoda from "../components/aboutus/Aboutmoda"
import Divider from "../components/Divider"
import About1 from "../components/aboutus/About1"
import About2 from "../components/aboutus/About2"
import Header from "../components/SEO/Header"

const AboutUs = () => {
  return (
    <>
    <Header subtitle={"About"} />
    <div className='overflow-hidden'>
    <div className="bg-th-lightgray min-h-screen w-screen">
    <Navbar />
    <div className={`bg-about-us w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>About us</h1>
    </div>
    <Aboutmoda />
    <Divider />
    <About1 />
    <About2 />
    <Divider />
    <p className="text-th-white text-left md:text-center w-11/12 lg:w-8/12 mx-auto my-8 lg:my-20 font-primary text-sm lg:text-base">Known for our historic yet modish environment, complimentary services and our impressive establishments, Hotel at the Waldorf is tailored for business travelers, urban leisure travelers and entertainment guests. Leisure travelers can venture to numerous attractions Vancouver has to offer. See the city for yourself and ask our staff for recommendations.</p>
    <Footer />
    </div>
    </div>
    </>
  )
}

export default AboutUs