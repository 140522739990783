import React from 'react'
import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import { useParams } from 'react-router-dom'
import room from '../accommodationData.json'
import suite from '../suites.json'
import symphony_place from '../symphony-place.json'
import {Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, EffectCoverflow, Navigation } from "swiper";
import Amenities from '../components/room/Amenities'
import Header from '../components/SEO/Header'

function Room() {
    let params = useParams()
    const rooms = room.concat(suite).concat(symphony_place)
    const roomData = rooms.find(room => room.id === parseInt(params.roomId, 10))

    return (
        <>
        <Header subtitle={roomData.name} />
        <div className='overflow-hidden'>
        <div className="bg-th-lightgray min-h-screen w-screen text-th-white">
            <Navbar />
            <div className={`bg-rooms w-screen h-[230px] md:h-[260px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
                <div className='filter-none uppercase font-secondary text-3xl md:text-3xl lg:text-5xl text-th-white flex flex-col justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>
                   <h1>
                        {roomData.name}
                       </h1>
               <h3 className='text-base md:text-lg lg:text-xl font-primary mt-1'>WITH {roomData.sub_name}</h3>
               </div>
            </div>
        <div className='relative mt-10 md:mt-20 w-full md:w-10/12 xl:w-9/12 mx-auto'>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                loop={true}
                pagination={false}
                navigation={{
                    prevEl: '.prev-arrow',
                    nextEl: '.next-arrow',
                    clickable: true,
                  }}
                coverflowEffect={{
                rotate: 5,
                depth: 0,
                modifier: 1,
                slideShadows: false,
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="mySwiper1"
            >
                {roomData.slide_pic.map((pic,) => (<SwiperSlide key={pic+roomData.id}><img src={pic} alt='roomimage' className='h-[270px] md:h-[550px] w-full object-contain' /></SwiperSlide>)
    
                )}
            </Swiper>
            <div className="prev-arrow absolute top-1/2 left-0 xl:-left-5 2xl:left-1 z-10 text-th-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 prev-arrow cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg></div>
            <div className="next-arrow absolute top-1/2 right-0 xl:-right-5 2xl:right-1 z-10 text-th-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
            </svg></div>
            </div>
            <div className='w-11/12 md:w-9/12 mt-9 md:mt-12 mx-auto'>
                <p className='font-primary text-base'>
                    {roomData.detail}
                </p>
            </div>
            <div>
            <Amenities amenities={roomData.amenities} />
            </div>
            <div className='w-11/12 md:w-9/12 mt-9 md:mt-12 mx-auto'>
                <p className='font-primary text-base mb-3 underline'>*Some rooms only - please request at time of booking</p>
                <p className='font-primary text-base'><span className='font-secondary tracking-wide'>**PARKING</span> - Hotel at the Waldorf has public parking lot located adjacent to the building. It is accessible by heading west on E. Hastings Street, towards downtown Vancouver. The parking lot operates on a first come, first serve basis and is therefore subject to availability. We are not liable for any break-ins or thefts, so we ask that you do not keep any valuables in your vehicle.</p>
            </div>
            <div className='flex justify-center my-16 md:my-20'>
                <a href='https://www.bookonthenet.net/west/premium/eresmain.aspx?id=ESiActPPCPiOyaLYD%2brtapYZtULWyfLMUSe%2b%2f0XpIlY%3d' target="_blank" rel="noreferrer" className='cursor-pointer text-th-white bg-th-primary bg-opacity-75 font-primary px-7 md:px-10 py-3 md:py-5 tracking-wider hover:bg-th-secondary'>BOOK NOW</a>
            </div>
            <Footer />
        </div>
        </div>
        </>
    )
}

export default Room