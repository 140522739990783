import React from 'react'

const Adress = () => {
  return (
    <div className='border border-th-white text-th-white w-full lg:w-80 h-70 md:h-56 lg:h-80 flex flex-col md:flex-row lg:flex-col justify-center lg:justify-start space-x-0 md:space-x-10 lg:space-x-0 space-y-2 items-center py-5 md:py-8 px-10'>
        <svg xmlns="http://www.w3.org/2000/svg" className=" fill-th-white bg-th-secondary w-16 h-16 md:w-20 md:h-20 p-4" viewBox="0 0 16 16">
        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
        </svg>
        <div className='pt-3 md:pt-0 lg:pt-7 text-center'>
        <h3 className='font-secondary text-xl md:text-3xl uppercase tracking-wider lg:tracking-wide pb-2'>Address</h3>
        <p className='font-primary font-light lg:font-normal text-sm md:text-base'>
        1489 E Hastings Street<br/>
        Vancouver, BC <br />
         V6L 1S4<br />
        </p>
        </div>
    </div>
  )
}

export default Adress