import React from "react";
import { Link } from "react-router-dom";
import { AboutImgBg } from "../../style/home.styled";

const AboutSection = () => {

  return (
    <div className="bg-th-lightgray text-th-white w-screen mx-auto pt-40 md:pt-48 pb-16">
      <div className="w-full h-fit flex flex-col lg:flex-row mx-auto justify-center items-end space-x-none lg:space-x-8 2xl:space-x-12 2xl:max-w-[1800px]">
        <AboutImgBg>
          <div className="w-100 h-0.5 md:h-1 z-10 bg-th-secondary relative -top-12 md:-top-16 lg:-top-24 bg-opacity-80"></div>
          <h1 className="absolute z-0 -top-10 md:-top-11 xl:-top-20 font-secondary text-2xl md:text-3xl xl:text-[40px] 2xl:text-5xl tracking-wider lg:tracking-widest left-2 lg:left-40 xl:left-40 2xl:left-60 whitespace-nowrap">
            ABOUT WALDORF
          </h1>
        </AboutImgBg>
        <div className="w-11/12 md:w-10/12 lg:w-1/2 max-w-4xl mx-auto mt-0 pt-0 pb-0 md:pt-3 2xl:pt-20 pr-0 lg:pr-5 space-y-3 2xl:space-y-8 flex flex-col justify-center items-start">
          <h1 className="text-2xl xl:text-3xl 2xl:text-4xl font-bold font-secondary tracking-wider mt-0 md:mt-8 xl:mt-0 ml-2">
            WELCOME
          </h1>
          <p className="p-0 lg:py-2 px-2 2xl:px-3 text-sm xl:text-base 2xl:text-lg font-primary w-full mt-4 xl:mt-0">
          Vancouver is a city of artistic expression and cultural exploration. it is the creative essence in all vancouverites that loves to embrace and revisit the iconic moments of our past, and morph them into our experiences of today.          </p>
          <div className="self-start lg:self-end ml-2 lg:ml-0 mr-0 lg:mr-5 2xl:mr-10 pt-5 md:pt-3 lg:pt-1 2xl:pt-4 text-base md:text-base xl:text-lg 2xl:text-xl">
            <Link to="/about" className="leading-none tracking-wider cursor-pointer font-secondary flex gap-1 md:gap-3 items-center border-b pt-2.5 pb-1.5 px-3 transition-all duration-300 hover:tracking-widest"> 
              READ MORE
              <svg
                className="w-10 h-4 pb-1"
                viewBox="0 0 62 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M61.7034 9.5L53.0432 0.839746L44.3829 9.5L53.0432 18.1603L61.7034 9.5ZM0.919922 11H53.0432V8H0.919922V11Z"
                  fill="#F09D8B" 
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
