import "./App.css";
import { Footer } from "./components/Footer"
import { Hero } from "./components/home/Hero"
import AboutSection from "./components/home/AboutSection"
import Accommodation from "./components/home/Accommodation"
import Dinning from "./components/home/Dinning"
import EventSection from "./components/home/EventSection"
import Map from "./components/home/Map"
import { NavbarHome } from "./components/NavbarHome";
import Header from "./components/SEO/Header";

function App() {
  return (
    <>
    <Header subtitle={"Vancouver, BC Hotel"} />
    <div className="overflow-hidden">
    <div className="bg-th-lightgray min-h-screen w-screen">
    <NavbarHome />
    <Hero />
    <AboutSection />
    <Accommodation />
    {/* <div id="dinings">
    <Dinning />
    </div> */}
    {/* <EventSection /> */}
    <Map />
    <Footer />
    </div>
    </div>
    </>
  );
}

export default App;
