import React from 'react'

const Aboutmoda = () => {
  return (
    <div className="mt-9 mb-11 md:my-14 text-th-white">
    <h2 className='font-secondary text-th-white uppercase text-2xl lg:text-3xl xl:text-4xl tracking-wider text-center'>ABOUT Waldorf</h2>
    <div className='w-full lg:w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto my-3 lg:my-16'>
    <h4 className='text-sm md:text-base lg:text-lg mx-2 lg:mx-0 font-secondary text-center tracking-normal lg:tracking-wide'>
    Vancouver is a city of artistic expression and cultural exploration. it is the creative essence in all vancouverites that loves to embrace and revisit the iconic moments of our past, and morph them into our experiences of today.    </h4>
    <p className='w-11/12 lg:w-full font-primary pt-5 text-sm lg:text-base text-justify mx-auto'>
    There is no better place that demonstrates this passion than Hotel at the Waldorf, Vancouver's famed cultural hub in much loved and vibrant East Van. From our iconic Polynesian themed Tiki Bar and venues Tabu and the Hideaway, to our retro hotel rooms, Hotel at the Waldorf has you stepping back in time to relive different eras of Vancouver. Hotel at the Waldorf is an experience to be explored with a sense of whimsy and a relaxed attitude. 
    </p>
    </div>
    </div>
  )
}

export default Aboutmoda