import React from 'react'

const About1 = () => {
  return (
    <div className='my-14 md:my-28'>
        <div className='w-screen h-[320px] lg:h-[480px] relative bg-home bg-cover bg-center'>
            <div className='absolute top-0 left-0 w-full lg:w-1/2 h-full bg-th-black/60 text-th-white flex flex-col justify-center items-center space-y-6 lg:space-y-9'>
                <h1 className='text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl font-secondary text-justify'>A gem in the heart of the city</h1>
                <p className='text-justify font-primary lg:text-center w-11/12 lg:w-8/12 leading-snug lg:leading-loose text-sm lg:text-base'>Downtown Vancouver is easily accessed and just minutes away from Hotel at the Waldorf. The hotel is also situated a short way from Commercial Drive and Gastown; two eclectic and historic hubs with the newest restaurants and boutique shops.</p>
            </div>
        </div>
    </div>
  )
}

export default About1