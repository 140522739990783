import { useState, useEffect, useRef } from "react"
import { Navbar } from "../components/Navbar"
import { Footer } from "../components/Footer"
import Divider from "../components/Divider"
import ExploreHead from "../components/explore/ExploreHead"
import AccessBtns from "../components/explore/AccessBtns"
import Walking from "../components/explore/Walking"
import Popular from "../components/explore/Popular"
import LandMark from "../components/explore/Landmark"
import DinningBars from "../components/explore/DinningBars"
import Others from "../components/explore/Others"
import Header from '../components/SEO/Header'


const Explore = () => {
  const [category, setCategory] = useState(null)
  const area = useRef(null);
  const executeScroll = () => area.current.scrollIntoView()

  useEffect(() => {
   if(category) executeScroll()
  }, [category])

  return (
    <>
    <Header subtitle={"Explore Vancouver"} />
    <div className='overflow-hidden'>
    <div className="bg-th-lightgray min-h-screen w-screen">
    <Navbar />
    <div className={`bg-explore w-screen h-[210px] lg:h-[400px] bg-no-repeat bg-cover bg-center bg-opacity-75`}>
        <h1 className='filter-none uppercase font-secondary text-2xl md:text-3xl lg:text-5xl text-th-white flex justify-center items-center h-full pt-28 lg:pt-40 tracking-wider'>EXPLORE VANCOUVER </h1>
    </div>
    <ExploreHead />
    <Divider />
    <AccessBtns setCategory={setCategory} />
    <div ref={area}>
    <Divider />
    {category==='walk' ?
    <Walking />
    : category==='popular'?
    <Popular />
    :category==='landmark'?
    <LandMark />
    :category==='dining'?
    <DinningBars />
    :category==='other'?
    <Others />
    :<Walking />
    }
    </div>
    <Footer />
    </div>
    </div>
    </>
  )
}

export default Explore