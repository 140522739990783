import React from 'react'

const Sales = () => {
  return (

<div className='text-th-white border w-full lg:w-1/2 2xl:w-1/3'>
<div className=' bg-th-secondary w-full flex flex-col md:flex-row items-center md:items-end justify-center px-5 xl:px-10 pt-4 md:pt-8 pb-4'>
<h2 className='font-secondary w-full lg:w-2/3 text-xl md:text-3xl xl:text-4xl tracking-wider text-center md:text-left'>SALES <br/> INQUIRIES</h2>
<p className='font-primary text-sm text-center md:text-right'>Group bookings and corporate rates inquires</p>
</div>
<div className='py-5 md:py-10 flex space-x-5 items-center justify-center'>
<svg xmlns="http://www.w3.org/2000/svg" className=' fill-th-white w-7 md:w-12 h-7 md:h-12' viewBox="0 0 16 16">
<path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
</svg>
    <a href="mailto:info@hotelatthewaldorf.ca" className='link font-primary text-base md:text-lg'>info@hotelatthewaldorf.ca</a>
</div>
</div>
  )
}

export default Sales